// src/templates/project-post.js
import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { resolveImageUrl } from "../utils/resolveImageUrl"
import gridViewButtonn from '../assets/images/grid-view-button.svg'
import circleArrow from '../assets/images/arrow-circle.svg'

const parseMarkdown = (text) => {
  return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
}

const ProjectPostTemplate = ({
  data: { site, markdownRemark: post, previous, next },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`
  const attachments = [
    resolveImageUrl(post.frontmatter.attachment1),
    resolveImageUrl(post.frontmatter.attachment2),
    resolveImageUrl(post.frontmatter.attachment3),
    resolveImageUrl(post.frontmatter.attachment4),
    resolveImageUrl(post.frontmatter.attachment5),
  ].filter(Boolean)

  return (
    <Layout location={location} title={siteTitle}>
      <div className="">
        {post.frontmatter.imgSrc && (
          <a href={post.frontmatter.website} target="_blank" rel="nofollow">
            <img
              src={post.frontmatter.imgSrc}
              alt={post.frontmatter.title}
              className="w-full"
            />
          </a>
        )}
      </div>

      <div className="md:w-9/12 mx-auto px-5 lg:px-0 my-10">
        {attachments.length > 0 && (
          <div className="mb-8 md:mb-20">
            <div className="grid grid-cols-1 gap-5">
              {attachments.map((attachment, index) => (
                <img
                  key={index}
                  src={attachment}
                  alt={`Attachment ${index + 1}`}
                  className="w-full"
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="md:w-9/12 mx-auto px-5 py-5 lg:px-0 text-sm lg:my-0">
        <article
          className="articleBody"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header className="mb-8 md:mb-20 lg:mb-5">
            <h1 itemProp="headline" className="CyGroteskGrandBold text-lg mb-0">{post.frontmatter.title}</h1>
            {post.frontmatter.website && (
              <p className="text-sm leading-5">
                <a href={post.frontmatter.website} target="_blank" rel="nofollow">{post.frontmatter.website}</a>
              </p>
            )}
            <p>{post.frontmatter.date}</p>
          </header>

          <section
            dangerouslySetInnerHTML={{ __html: parseMarkdown(post.html) }}
            itemProp="articleBody"
            className="text-sm leading-5"
          />
        </article>
      </div>

      <div className="grid grid-cols-3 gap-5 lg:my-10">
        <div className="flex justify-center items-center py-5">
          {previous && (
            <Link to={`/work${previous.fields.slug}`} rel="prev" className="flex items-center">
              <img src={circleArrow} className="w-14" alt="Previous Project" />
            </Link>
          )}
        </div>

        <div className="flex justify-center items-center">
          <Link to="/work" className="">
            <img src={gridViewButtonn} className="w-10" alt="Grid View" />
          </Link>
        </div>

        <div className="flex justify-center items-center py-5">
          {next && (
            <Link to={`/work${next.fields.slug}`} rel="next" className="flex items-center">
              <img src={circleArrow} className="w-14 transform scale-x-[-1]" alt="Next Project" />
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default ProjectPostTemplate

export const query = graphql`
  query ProjectPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 270)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        imgSrc
        website
        description
        attachment1
        attachment2
        attachment3
        attachment4
        attachment5
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
